import React from "react"
import { graphql } from "gatsby"

// imported components
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Longcard} from "../components/card";

// imported styles
import mainStyles from "../styles/css/main.module.scss"

export default ({ data }) => {
  return (
    <Layout>
      <SEO 
        title="Build a Product"
        description="Bootstrap your product experience by building a no-code product"
      />

      <section className={mainStyles.majorSection}>
        <div className={mainStyles.mainPadding}>

        <div className={[mainStyles.mainExplanatoryContent_wide, mainStyles.paddingBottom3].join(' ')}>
          <h1>Complete a Product Case</h1>
          <p>Learn how to create requirements and set strategy</p>
        </div>

          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Longcard
              cardName = {node.frontmatter.number}
              cardTitle = {node.frontmatter.title}
              cardBody = {node.frontmatter.description}
              cardPage = {node.fields.slug}
            />
          ))}

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "build"}}},
      sort: {fields: [frontmatter___number], order: ASC }
    ) 
      {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            number
            description
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`